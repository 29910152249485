.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.whatsapp-float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  background-color: green;
  color: #fff;
  border-radius: 50px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

.whatsapp-float:hover {
  background-color: green;
  color: #fff;
}

a.whatsapp-float {
  text-decoration: none;
}
.phone-float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 100px;
  right: 40px;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity, 1));
  color: #fff;
  border-radius: 50px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

.phone-float:hover {
  background-color: green;
  color: #fff;
}

a.phone-float {
  text-decoration: none;
}
