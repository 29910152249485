


@tailwind base;
@tailwind components;
@tailwind utilities;



body{
    @apply w-screen overflow-x-hidden;
}